//@ts-nocheck
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  CircularProgress,
  darken,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { TableSortOrder } from '../../../types/table';
import { getSorting, stableSort } from '../../../utils/sorting';

import { v4 as uuidv4 } from 'uuid';
import colors from '../../../utils/theme/colors';


export const DEFAULT_ROWS_PER_PAGE = '50';
export const INITIAL_ROWS_PER_PAGE = 100;
export const ROWS_PER_PAGE = [5, 10, 15, 20, 50, 100];


export interface IRowField {
  id: string;
  data: string | number | JSX.Element;
  align?: string;
  style?: { [key: string]: string };
  notTruncate?: boolean;
}

export interface IRow {
  id: string | number;
  payload: unknown;
  fields: IRowField[];
  style?: React.CSSProperties;
  collapsibleContent?: JSX.Element;
}

export interface IHeader {
  id: string;
  label: string;
  align?: string;
  sorting: boolean;
  wrap?: boolean;
  disablePadding?: boolean;
  type?: string;
  style?: { [key: string]: string };
}

export enum TablePaginationEnum {
  local = 'local',
  server = 'server',
}

interface IProps {
  headers: IHeader[];
  rows: IRow[];
  sortBy: string;
  withPagination?: TablePaginationEnum;
  sortOrder?: TableSortOrder;
  selectable?: boolean;
  collapsible?: boolean;
  isFetching?: boolean;
  initialRowsPerPage?: number;
  rowsPerPageOptions?: number[];
  size?: 'small' | 'medium';
  stickyHeader?: boolean;
  tableHeight?: number | string;
  rowHeight?: number | string;
  noDataLabel?: JSX.Element;
  pageProp?: number;
  rowsPerPageProp?: number;
  tableLayout?: string;
  totalRecordsProps?: number;
  onSelectCallback?: (items: string[]) => void;
  onRowClickCallback?: (item: string, payload: unknown) => void;
  onPageChange?: (page: number) => void;
  onRowsPerPageChange?: (rowsPerPage: number) => void;
  onSortChange?: (sortBy: string, order: TableSortOrder) => void;
}

const EnhancedTable: React.FunctionComponent<IProps> = ({
  headers,
  rows = [],
  sortBy,
  withPagination,
  selectable,
  collapsible,
  isFetching,
  stickyHeader,
  size = 'small',
  sortOrder = TableSortOrder.asc,
  rowHeight = '46px',
  tableLayout = 'fixed',
  noDataLabel,
  pageProp,
  rowsPerPageProp,
  totalRecordsProps,
  rowsPerPageOptions = ROWS_PER_PAGE,
  initialRowsPerPage = INITIAL_ROWS_PER_PAGE,
  onSelectCallback,
  onRowClickCallback,
  onPageChange,
  onRowsPerPageChange,
  onSortChange,
}) => {
  const [order, setOrder] = useState(sortOrder);
  const [orderBy, setOrderBy] = useState(sortBy);
  const [page, setPage] = useState(pageProp ?? 0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageProp || initialRowsPerPage);
  const [selected, setSelected] = useState<string[]>([]);
  const [collapsedRows, setCollapsedRows] = useState<{ [rowId: string]: boolean }>({});

  const onRowCollapse = (rowId: string | number) => () => {
    setCollapsedRows(v => ({ ...v, [rowId]: !v[rowId] }));
  };

  const onSelectItem = (items: string[]) => {
    setSelected(items);

    if (onSelectCallback) {
      onSelectCallback(items);
    }
  };

  const handleRequestSort = (property: string) => {
    const isDesc = orderBy === property && order === TableSortOrder.dsc;

    setOrder(isDesc ? TableSortOrder.asc : TableSortOrder.dsc);
    setOrderBy(property);
    onSortChange?.(property, isDesc ? TableSortOrder.asc : TableSortOrder.dsc);
  };

  const handleChangePage = (_: React.MouseEvent<HTMLElement, MouseEvent> | null, newPage: number) => {
    onPageChange?.(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = event?.target?.value || INITIAL_ROWS_PER_PAGE?.toString();

    if (target) {
      onRowsPerPageChange?.(Number(target));
      setRowsPerPage(Number(target));
    }
  };

  const filterRows = (rowsA: IRow[]) => {
    return rowsA;
  };

  const memoizedRows = useMemo(() => filterRows(rows), [rows]);
  const memoizedRowsLength = memoizedRows.length;

  const createSortHandler = (property: string) => () => {
    if (property) {
      handleRequestSort(property);
    }
  };

  const getLabel = ({ id, sorting, label }: IHeader) => {
    if (sorting) {
      return (
        <>
          <TableSortLabel active={orderBy === id} direction={order} onClick={createSortHandler(id)}>
            {label}
          </TableSortLabel>
        </>
      );
    }

    return <>{label}</>;
  };

  const handleClick = (rowId: string, row: IRow) => () => {
    if (selectable) {
      const selectedIndex = selected.indexOf(rowId);
      let newSelected: string[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, rowId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }

      onSelectItem(newSelected);
    } else {
      if (!onRowClickCallback) {
        return;
      }

      onRowClickCallback(rowId, row?.payload);
    }
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const renderPagination = () => (
    <TablePagination
      data-testid='EnhancedTable-TablePagination'
      component='div'
      count={totalRecordsProps || memoizedRowsLength}
      page={pageProp ?? page}
      rowsPerPageOptions={rowsPerPageOptions}
      rowsPerPage={rowsPerPage}
      labelRowsPerPage='Rows per page'
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      SelectProps={{ SelectDisplayProps: { id: 'EnhancedTable-select-rows-per-page' } }}
    />
  );

  const getBodyRows = () => {

    if (withPagination === TablePaginationEnum.server) {
      return stableSort(filterRows(rows), getSorting(order, orderBy));
    }

    return stableSort(filterRows(rows), getSorting(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    )
  };

  if (isFetching) {
    return <StyledCircularProgress data-testid='EnhancedTable-CircularProgress' />;
  }

  if (getBodyRows().length === 0) {
    return (
      <NoDataToShowLabel data-testid='EnhancedTable-no-data-to-show'>
        {noDataLabel || 'No data available yet!'}
      </NoDataToShowLabel>
    );
  }

  const renderActions = (field: IRowField) => {
    return (
      <TableCell style={field.style} key={field.id} align={field.align}>
        {field.data}
      </TableCell>
    )
  }

  return (
    <>
      <TableContainer sx={{ maxHeight: '75vh' }}>
        <StyledTable size={size} stickyHeader={stickyHeader} tableLayout={tableLayout} >
          <TableHead>
            <TableRow style={{ height: rowHeight }}>
              {collapsible && <TableCell />}
              {(headers || []).map(header => {
                const { id = uuidv4(), align, wrap = true, disablePadding } = header;

                return (
                  <StyledTableCellHeader
                    wrap={wrap ? 1 : 0}
                    key={id}
                    align={align}
                    padding={disablePadding ? 'none' : 'normal'}
                    sortDirection={orderBy === id ? order : false}
                    style={{ width: header?.style?.width || 'inherit', backgroundColor: 'white' }}
                  >
                    {getLabel(header)}
                  </StyledTableCellHeader>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {getBodyRows()?.map((row, idx) => (
              <TableRow
                key={row.id}
                style={{ height: rowHeight, cursor: 'pointer', ...row?.style }}
                hover={true}
                onClick={handleClick(row?.id?.toString(), row)}
                aria-checked={isSelected(row?.id?.toString())}
                tabIndex={-1}
                selected={isSelected(row?.id?.toString())}
              >
                {row?.fields?.map(field => {
                  if (field.id === 'actions') return renderActions(field)
                  return (
                    <TableCell style={field.style} key={field.id} align={field.align}>
                     <CellLabelEllipsis>{field.data}</CellLabelEllipsis>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </TableContainer>
      {withPagination && renderPagination()}
    </>
  );
};

export default EnhancedTable;

const StyledTable = styled(Table) <{ tableLayout: string }>`
  table-layout: ${({ tableLayout }) => tableLayout};
`;

const NoDataToShowLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1;
  padding: 24px;
`;

const StyledCircularProgress = styled(CircularProgress)`
  margin: 32px 48%;
`;

const TableCellHeader = styled(TableCell) <{ wrap: 1 | 0 }>`
  ${({ wrap = 1 }) => (!wrap ? 'white-space: nowrap;' : '')}
`;

const StyledTableCellHeader = styled(TableCellHeader)`
  color: ${darken(colors.grey.main, 0.3)};
`;


const TextEllipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;


const CellLabelEllipsis = styled.span<{ notTruncate: boolean }>`
  display: block;
  ${({ notTruncate }) => notTruncate ? '' : TextEllipsis}
`;