//@ts-nocheck
import { BasicUserInfo, useAuthContext } from "@asgardeo/auth-react";
import { FunctionComponent, ReactElement, useEffect, useState } from "react";

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation, useParams } from "react-router-dom";
import AsgardeoUsers from "./AsgardeoUsers";
import borderApiClient from '../../api/borderApiClient';
import restApiClient from '../../api/restApiClient';
import { Paper } from '@mui/material';
import { Providers } from '../../types/providers';


export type asgardeoUsers = {
  "totalResults": 1,
  "startIndex": 1,
  "itemsPerPage": 1,
  "schemas": [
    "urn:ietf:params:scim:api:messages:2.0:ListResponse"
  ],
  "Resources": {
    "meta": {
      "created": "2018-08-17T10:34:29Z",
      "location": "https://api.asgardeo.io/t/{root-organization-name}/o/scim2/Users/008bba85-451d-414b-87de-c03b5a1f4217",
      "lastModified": "2018-08-17T10:34:29Z",
      "resourceType": "User"
    },
    "schemas": [
      "urn:ietf:params:scim:schemas:core:2.0:User",
      "urn:ietf:params:scim:schemas:extension:enterprise:2.0:User"
    ],
    "userName": string;
    "id": string;
    "name": {
      "givenName": string;
      "familyName": string;
    },
    "emails": string[],
    "urn:ietf:params:scim:schemas:extension:enterprise:2.0:User": {
      "manager": {
        "value": "Taylor"
      }
    },
    "roles": [
      {
        "audienceType": string;
        "display": string;
        "type": "default",
        "value": "Internal/everyone"
      }
    ]
  }[]
}

interface DerivedState {
  authenticateResponse: BasicUserInfo,
  idToken: string[],
  decodedIdTokenHeader: string,
  decodedIDTokenPayload: Record<string, string | number | boolean>;
}

const AsgardeoMain: FunctionComponent = (): ReactElement => {
  const location = useLocation();
  const params = useParams();
  const {
    state,
    signIn,
    signOut,
    getBasicUserInfo,
    getIDToken,
    getAccessToken,
    getDecodedIDToken,
  } = useAuthContext();

  const [users, setUsers] = useState<asgardeoUsers>();

  const [derivedAuthenticationState, setDerivedAuthenticationState] = useState<DerivedState | null>(null);
  const [hasAuthenticationErrors, setHasAuthenticationErrors] = useState<boolean>(false);
  const [hasLogoutFailureError, setHasLogoutFailureError] = useState<boolean>();
  const [fetching, setFetching] = useState(true);

  const search = location.search;

  const stateParam = new URLSearchParams(search).get('state');
  const errorDescParam = new URLSearchParams(search).get('error_description');

  useEffect(() => {

    if (!state?.isAuthenticated) {
      return;
    }

    (async (): Promise<void> => {
      try {
      const countryCode = params.countryCode;
      const tenantId = params.tenant;
      const accessToken = await getAccessToken();
      const idToken = await getIDToken();

      if (!countryCode || !accessToken || !idToken || !tenantId) return console.error("Missing required parameter");
      const restApiToken = await restApiClient.fetchApiAccessToken(countryCode, Providers.asgardeo, accessToken, idToken, tenantId);

      const users = await borderApiClient.fetchUsers(countryCode, tenantId, restApiToken?.access_token);
      setUsers(users as any)
    } catch (err) {
      console.error(err)
    } finally {
      setFetching(false)
    }
    })();
  }, [state.isAuthenticated, params, getIDToken, getAccessToken]);


  const handleLogin = () => {
    setHasLogoutFailureError(false);
    signIn({ state: location.pathname })
      .catch(() => setHasAuthenticationErrors(true));
  };

  const handleLogout = () => {
    signOut();
  };

  useEffect(() => {
    if (state.isLoading) return;
    if (state.isAuthenticated) return;
    handleLogin();
  }, [state.isLoading, state.isAuthenticated])

  console.log(state)
  if (state.isLoading || !state.isAuthenticated || fetching) return (
    <Box display='grid' justifyContent='center' alignContent='center' height='100%'>
      <CircularProgress style={{ color: 'white' }} />
    </Box>
  );

  return <AsgardeoUsers users={users} />;
};

export default AsgardeoMain;