import styled from '@emotion/styled';

// .st0{fill:#FF7300;}
// .st1{fill:#231F20;}
const AsgardeoLogo = () => {
  return (
    <StyledSvg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      viewBox="0 0 328.2 49.5" >

      <g id="Layer_2_00000078044156095402759710000017614241463327301801_">
        <polygon className="st0" fill='#FF7300' points="322.2,0 324.1,3.4 322,7.1 326.3,7.1 328.2,10.5 316.1,10.5 	" />
        <polygon className="st0" fill='#FF7300' points="307,10.5 309,7.1 313.3,7.1 311.1,3.4 313.1,0 319.1,10.5 	" />
        <polygon className="st1" fill='#231F20' points="319.8,18.4 317.6,14.6 315.5,18.4 311.5,18.4 317.6,7.9 323.7,18.4 	" />
        <path className="st1" d="M44.5,47.5v-7.9h19.8c1.8,0,3.3-1.5,3.3-3.3c0,0,0,0,0,0c0-1.8-1.4-3.3-3.3-3.3c0,0,0,0,0,0h-8.7
		c-3,0.1-5.8-1.1-7.9-3.3c-2.1-2.1-3.3-4.9-3.2-7.9c-0.1-3,1.1-5.8,3.3-7.9c2.1-2.1,4.9-3.3,7.9-3.3h19.9v7.8H55.6
		c-1.8,0-3.3,1.5-3.3,3.3c0,0.9,0.3,1.7,0.9,2.3c0.6,0.6,1.5,1,2.3,1h8.7c6.2,0,11.2,5,11.2,11.1c0,0,0,0,0,0
		c0,6.2-5,11.2-11.2,11.2c0,0,0,0,0,0H44.5z"/>
        <path className="st1" fill='#231F20'  d="M96.7,47.5c-4.9,0.1-9.6-1.8-13-5.4c-3.5-3.4-5.5-8.1-5.4-13c-0.1-4.9,1.9-9.6,5.4-13c3.4-3.5,8.1-5.5,13-5.4
		h15.5v7.8H96.7c-5.8,0-10.5,4.7-10.5,10.5c0,0,0,0,0,0c0,5.8,4.7,10.5,10.5,10.5l0,0h7.6V33h-5.8v-7.9h13.6v22.3L96.7,47.5z"/>
        <path className="st1" fill='#231F20'  d="M187.2,47.5l-17.7-17.6v17.6h-7.9V10.7h19.8c3-0.1,5.8,1.1,7.9,3.3c2.1,2.1,3.3,4.9,3.3,7.9
		c0.1,3-1.1,5.8-3.3,7.9c-0.9,0.9-2,1.7-3.1,2.3l-2.3,1.1l14.3,14.3L187.2,47.5z M181.4,25.2c1.8,0,3.3-1.5,3.3-3.3
		c0-0.9-0.3-1.7-0.9-2.3c-0.6-0.6-1.5-1-2.3-1h-12v6.6L181.4,25.2z"/>
        <path className="st1" fill='#231F20'  d="M201.1,47.5V10.7h15.4c4.9-0.1,9.6,1.9,13,5.4c3.5,3.4,5.5,8.1,5.4,13c0.1,4.9-1.9,9.6-5.4,13
		c-3.4,3.5-8.1,5.5-13,5.4L201.1,47.5z M216.5,39.6c5.8,0,10.5-4.7,10.6-10.5c0,0,0,0,0,0c0-5.8-4.7-10.5-10.5-10.5c0,0,0,0,0,0
		h-7.6v21H216.5z"/>
        <path className="st1"fill='#231F20'  d="M289.5,47.5c-4.9,0.1-9.6-1.9-13-5.4c-3.5-3.4-5.5-8.1-5.4-13c-0.1-4.9,1.9-9.6,5.4-13c7.2-7.2,18.8-7.2,26,0
		c0,0,0,0,0,0c3.5,3.4,5.5,8.1,5.4,13c0.1,4.9-1.9,9.6-5.4,13C299.1,45.7,294.3,47.6,289.5,47.5z M289.5,18.6
		c-5.8,0-10.5,4.7-10.5,10.5c0,5.8,4.8,10.5,10.6,10.5c5.8,0,10.5-4.8,10.5-10.6c0-2.8-1.1-5.4-3-7.4
		C295,19.7,292.3,18.6,289.5,18.6z"/>
        <polygon className="st1"  fill='#231F20' points="0,47.5 8.8,47.5 21.2,26 33.7,47.5 42.4,47.5 21.2,10.7 	" />
        <polygon className="st1"fill='#231F20'  points="115.6,47.5 124.4,47.5 136.8,26 149.3,47.5 158.1,47.5 136.8,10.7 	" />
        <polygon className="st1" fill='#231F20' points="238.3,47.4 238.3,10.8 269,10.8 269,18.5 246.2,18.5 246.2,25.3 263.3,25.3 263.3,32.9 246.2,32.9 
		246.2,39.6 269,39.6 269,47.4 	"/>
      </g>
    </StyledSvg>
  );
}


export default AsgardeoLogo;

const StyledSvg = styled.svg`
  width: 90px;

`;