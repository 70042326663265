import { FC } from "react";
import PaperLayout from '../components/common/layouts/PaperLayout';
import { Box, Typography, Button } from '@mui/material';
import { error } from 'console';

const LogoutRoute: FC = () => {
  return (
    <PaperLayout>
      <Box p={4}>
        <Typography variant='h3' mb={2} align='center'>You sucessfully logged out</Typography>
        <Typography variant='body1' mb={4}>

        </Typography>
        <Box textAlign='center'>
          <Button onClick={() => (window.location.href = "/")}>
            Login
          </Button>
        </Box>
      </Box>
    </PaperLayout>
  );
};

export default LogoutRoute;