import React from 'react';
import ReactDOM from 'react-dom/client';

import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider
} from "react-router-dom";

import { AuthProvider as AsgardeoAuthProvider } from '@asgardeo/auth-react';
import { ThemeProvider } from '@mui/material/styles';
import AppLayout from './components/common/layouts/AppLayout';
import AppRoute from './routes/AppRoute';
import LogoutRoute from './routes/LogoutRoute';
import { getAsgardeoConfig } from './utils/providerConfigs/asgardeo-config';
import { theme } from './utils/theme/mui';
import RootErrorBoundary from './components/common/RootErrorBoundary';

import './styles/global.css';

const router = createBrowserRouter([
  {
    index: true,
    element: <Navigate replace to='/dashboard' />,
  },
  {
    path: "/",
    errorElement: <RootErrorBoundary />,
    children: [
      {
        path: '/dashboard',
        element: <AppLayout />,
        children: [
          {
            path: "/dashboard/:countryCode/:provider/:tenant",
            element: <AppRoute />,
          },
        ]
      },
      {
        path: "/logout",
        element: <LogoutRoute />,
      },
    ],
  },
]);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AsgardeoAuthProvider config={getAsgardeoConfig()}>
        <RouterProvider router={router} />
      </AsgardeoAuthProvider>
    </ThemeProvider>
  </React.StrictMode>
);