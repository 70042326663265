import { Providers } from '../types/providers';


class RestApiClient {

  public fetchApiAccessToken = async (countryCode: string, providerName: Providers, accessToken: string, idToken: string, tenantId: string) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "provider": providerName,
        "access_token": accessToken,
        "id_token": idToken,
        "tenant_id": tenantId
      }),
    };
    const res = await fetch(
      `https://${countryCode}-proxy-mt-01.preprod.incountry.io/validate/iamadmin/${providerName}/token`, requestOptions
    );

    return await res?.json();
  };
}

const restApiClient = new RestApiClient();

export default restApiClient;
