import styled from '@emotion/styled';
import { faker } from '@faker-js/faker';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { FC, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import AsgardeoLogo from '../common/logos/AsgardeoLogo';
import EnhancedTable, { IHeader, IRow } from '../common/EnhancedTable';
import LaunchIcon from '@mui/icons-material/Launch';
import { formatDate } from '../../utils/dates';
import { asgardeoUsers } from './AsgardeoMain';

interface IProps {
  users?: asgardeoUsers;
}

const AsgardeoUsers: FC<IProps> = ({users}) => {
  const [search, onSearchChange] = useState<string>('');

  const renderTableHeaders = (): IHeader[] => [
    { id: 'id', align: 'left', sorting: true, label: 'ID' },
    { id: 'username', align: 'left', sorting: true, label: 'Username'},
    { id: 'emails', align: 'left', sorting: true, label: 'Email', },
    { id: 'name', align: 'left', sorting: true, label: 'Name', },
    { id: 'roles', align: 'left', sorting: true, label: 'Roles', },
    { id: 'createdAt', align: 'left', sorting: true, label: 'Created', },
    { id: 'updatedAt', align: 'left', sorting: true, label: 'Updated', },
    { id: 'actions', align: 'center', sorting: false, label: 'Asgardeo console', },
  ];

  const renderTableRows = (): IRow[] => {
    return (users?.Resources || [])?.filter(v => v?.userName?.includes(search))?.map((u, uIdx) => {
      return {
        id: uIdx,
        payload: u,
        fields: [
          { id: uuidv4(), data: u?.id, align: 'left' },
          { id: uuidv4(), data: u?.userName, align: 'left'},
          { id: uuidv4(), data: u?.emails.join(' ,'), align: 'left' },
          { id: uuidv4(), data: u?.name?.givenName + ' ' + u?.name?.familyName, align: 'left' },
          { id: uuidv4(), data: u?.roles.map(r => r?.audienceType + '/' + r?.display).join(', '), align: 'left' },
          { id: uuidv4(), data: formatDate(u?.meta?.created), align: 'left' },
          { id: uuidv4(), data: formatDate(u?.meta?.lastModified), align: 'left' },
          { id: uuidv4(), data: <IconButton onClick={() => {
            window.open(`https://console.asgardeo.io/t/orgoshs4/app/users/${u?.id}`)

          }}><LaunchIcon /></IconButton>, align: 'center' },
        ],
      };
    });
  };

  const renderToolbar = () => {
    const endAdornment = (
      <InputAdornment position='end'>
        <IconButton edge='end'>
          <SearchIcon />
        </IconButton>
      </InputAdornment>
    )

    return (
      <Toolbar>
        <Box py={2} width='100%' display='grid' gridTemplateColumns='1fr 300px' alignItems='center'>
          <Box>
            <AsgardeoLogo />
            <Typography
              variant="h4"
              id="tableTitle"
              component="div"
            >
              Users
              <Typography variant='caption'> (Total: {users?.totalResults})</Typography>
            </Typography>
          </Box>
          <StyledTextField variant='outlined' value={search} onChange={(evt) => onSearchChange(evt.target.value)} InputProps={{ endAdornment }} />
          <div />
        </Box>
      </Toolbar>
    );
  }

  return (
      <Paper sx={{ width: '100%', mb: 2, overflow: 'hidden' }}>
        {renderToolbar()}
        <EnhancedTable
          sortBy='emails'
          stickyHeader
          headers={renderTableHeaders()}
          rows={renderTableRows()}
          tableLayout='fixed'
        />
      </Paper>
  );
}

export default AsgardeoUsers;

const StyledTextField = styled(TextField)`

`;



// const USERS = {
//   "totalResults": 1,
//   "startIndex": 1,
//   "itemsPerPage": 1,
//   "schemas": [
//     "urn:ietf:params:scim:api:messages:2.0:ListResponse"
//   ],
//   "Resources": new Array(100).fill(null).map(() => ({
//     "meta": {
//       "created": "2018-08-17T10:34:29Z",
//       "location": "https://api.asgardeo.io/t/{root-organization-name}/o/scim2/Users/008bba85-451d-414b-87de-c03b5a1f4217",
//       "lastModified": "2018-08-17T10:34:29Z",
//       "resourceType": "User"
//     },
//     "schemas": [
//       "urn:ietf:params:scim:schemas:core:2.0:User",
//       "urn:ietf:params:scim:schemas:extension:enterprise:2.0:User"
//     ],
//     "username": "DEFAULT/" + faker.internet.username(),
//     "id": faker.string.uuid(),
//     "name": {
//       "givenName": faker.person.firstName(),
//       "familyName": faker.person.lastName()
//     },
//     "emails": [
//       faker.internet.email()
//     ],
//     "urn:ietf:params:scim:schemas:extension:enterprise:2.0:User": {
//       "manager": {
//         "value": "Taylor"
//       }
//     },
//     "roles": [
//       {
//         "type": "default",
//         "value": "Internal/everyone"
//       }
//     ]
//   }))
// }