export const getAsgardeoConfig = () => {
    const hostname = window.location.hostname;
    const port = window.location.port;

    const fullHost = `https://${hostname}${port ? ':' + port : ''}`;

    return ({
        "clientID": "Na1fvItLqtavc5pX46ZZ7PQd_n0a",
        "wellKnownEndpoint": "https://us-proxy-mt-01.preprod.incountry.io/x-inc-d534d8c2-caa7-4b0c-9bb6-8af24fca74d2/oauth2/token/.well-known/openid-configuration",
        "signInRedirectURL": fullHost + "/dashboard",
        "signOutRedirectURL": fullHost + "/logout",
        "scope": ["openid profile roles"]
    })
}