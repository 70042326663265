import { useRouteError } from 'react-router-dom';
import PaperLayout from './layouts/PaperLayout';
import { Box, Button, Typography } from '@mui/material';
import { useAuthContext } from '@asgardeo/auth-react';

interface IProps {

}

const RootErrorBoundary: React.FC<IProps> = () => {
  const error = useRouteError() as Error;
  const { error: asgardeoError } = useAuthContext();

  const getAsgardeoErrorMessage = () => {
    if (asgardeoError?.code === "SPA-CRYPTO-UTILS-VJ-IV01") {
      switch (asgardeoError?.message) {
        case "ERR_JWT_CLAIM_VALIDATION_FAILED nbf":
          return { title: 'Invalid date', text: "It looks like your computer's date and time is incorrect. Please validate and try again." }
        case "ERR_JWT_CLAIM_VALIDATION_FAILED iss":
          return { title: ' Issuer claim validation failed', text: "The configured BaseURL in config.json might be incorrect. Make sure to remove any trailing spaces if present." }
        default:
          return { title: "ID token validation failed", text: `Issue occurred while verifying ID token: ${error?.message}` }
      }
    } else if (asgardeoError?.code === "SPA-MAIN_THREAD_CLIENT-SI-SE01") {
      return { title: "Authentication error", text: "Please check application configuration and try login again" }
    }
  }

  const getErrorMessage = () => {
    if (asgardeoError) return getAsgardeoErrorMessage();
    return {title: "Something went wrong", text: error.message || (error as any).data}
  }

  return (
    <PaperLayout>
      <Box p={4}>
        <Typography variant='h3' mb={2}>{getErrorMessage()?.title}</Typography>
        <Typography variant='body1' mb={4}>
          {getErrorMessage()?.text}
        </Typography>
        <Box textAlign='center'>
          <Button onClick={() => (window.location.href = "/")}>
            Reload
          </Button>
        </Box>
      </Box>
    </PaperLayout>
  );
}

export default RootErrorBoundary;