import styled from '@emotion/styled';
import React, { ReactNode } from 'react';

import { Paper } from '@mui/material';

interface IProps {
  children: ReactNode;
}

const PaperLayout: React.FC<IProps> = ({ children }) => {

  return (
    <Wrapper>
      <StyledPaper>{children}</StyledPaper>
    </Wrapper>
  );
}


export default PaperLayout;

const Wrapper = styled.div`
    display: grid;
    grid-auto-flow: row;
    align-content: center;
    justify-items: center;
    gap: 48px;
    max-width: 400px;
    margin: 0 auto;
    height: 100%;
`;

const StyledPaper = styled(Paper)`
    min-height: 200px;
    width: 400px;
    background-color: white;
    border-radius: 8px;;
`;
