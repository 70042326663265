import { FC } from "react";

import { useParams } from "react-router-dom";
import AsgardeoMain from '../components/asgardeo/AsgardeoMain';
import { Providers } from '../types/providers';


const MainApp: FC = () => {
  const params = useParams();

  const getProviderApp = () => {
    switch (params.provider) {
      case Providers.asgardeo:
        return <AsgardeoMain />;
      case Providers.oktaWf:
      case Providers.oktaCust:
        return <div>not implemented</div>;
      default:
        return <div>unknown provider</div>
    }
  }

  return getProviderApp();
};

export default MainApp;