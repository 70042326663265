import styled from '@emotion/styled';
import React, { ReactNode, useEffect, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import InCountryLogo from '../logos/InCountryLogo';
import { useAuthContext } from '@asgardeo/auth-react';
import { Outlet, useLocation, useOutlet, useParams } from 'react-router-dom';
import RootRoute from '../../../routes/RootRoute';

interface IProps {

}

const AppLayout: React.FC<IProps> = () => {
  const params = useParams();

  const [user, setUser] = useState<any>();

  const {
    getBasicUserInfo,
    state,
    signOut,
  } = useAuthContext();

  useEffect(() => {
    if (!state.isAuthenticated) return;

    (async (): Promise<void> => {
      const userInfo = await getBasicUserInfo();

      setUser(userInfo)
    })();
  }, [state.isAuthenticated, getBasicUserInfo])
  console.log(user)
  return (
    <Wrapper>
      <Header>
        <Box display='grid' gridAutoFlow='column' gap={4} justifyContent='flex-start' alignItems='center'>
          <InCountryLogo />
          <Typography variant='h3' fontWeight='bold' color='white'>IAM admin</Typography>
          <Box justifySelf='start'>
            {state?.isAuthenticated && (
              <>
                <Typography variant='caption' color='white'>Organization:</Typography>
                <Typography color='white' fontWeight='bold'>{user?.orgName}</Typography>
              </>
            )}
          </Box>
        </Box>
        <Box justifySelf='end'>
          {state.isAuthenticated && (
            <Box display='grid' gridTemplateColumns='auto auto' alignItems='center' gap={2}>
              <Typography color='white'>{user?.username}</Typography>
              <Button variant='text' size='small' style={{ color: 'white', borderColor: 'white', textTransform: 'capitalize' }} onClick={() => { signOut() }}>Log Out</Button>
            </Box>
          )}
        </Box>
      </Header>
      <Content>
        {(!params.provider || !params.tenant) ? <RootRoute /> : <Outlet />}
      </Content>
    </Wrapper>
  );
}


export default AppLayout;

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 8px;
  height: 100%;
`;

const Header = styled.header`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  width: 1440px;
  margin: 0 auto;
  padding: 24px 72px;

`;

const Content = styled.div`
  width: 1440px;
  margin: 0 auto;
  padding:0px 72px;
`;
