import { FC, useEffect } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Providers } from '../types/providers';
import PaperLayout from '../components/common/layouts/PaperLayout';
import { Box, Typography } from '@mui/material';

const RootRoute: FC = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const stateParam = params.get('state');


  useEffect(() => {
    const stateParams = stateParam?.split('_');
    const asgardeoStateParam = stateParams?.find(p => p.includes(Providers.asgardeo));
    const oktaStateParam = stateParams?.find(p => p.includes(Providers.oktaCust));
    if (asgardeoStateParam) navigate(asgardeoStateParam);
    if (oktaStateParam) navigate(oktaStateParam);
  }, [stateParam, navigate])

  return (
    <PaperLayout>
      <Box p={4}>
        <Typography variant='h3' mb={2}>Required parameters are missing</Typography>
        <Typography variant='body1' mb={4}>
          Required parameters are missing in the URL. To obtain the correct URL, go to the <a href='https://portal.incountry.com' target='_blank'>InCountry Portal</a>, choose the desired country, navigate to Services, and view the IAM service credentials.
        </Typography>
      </Box>
    </PaperLayout>
  );;
};

export default RootRoute;